import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  savedShopDetails: {
    name: "",
    intro: "",
    address: "",
    city: undefined,
    state: undefined,
    pin: "",
    shopType: "",
    categories: [],
    subCategories: [],
    shopOwnerName: "",
    shopOwnerContactNumber: "",
    shopOwnerCancelledChequeImage: undefined,
    storeFrontPicture: undefined,
    lat: undefined,
    long: undefined,
    capturedAddress: "",
  },
  isSaved: false,
};

const savedShopSlice = createSlice({
  name: "saved-shop",
  initialState,
  reducers: {
    updateSavedShop: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    updateSavedShopMultipleKeys: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearSavedShop: () => initialState,
  },
});

export const { updateSavedShop, clearSavedShop, updateSavedShopMultipleKeys } =
  savedShopSlice.actions;

export default savedShopSlice.reducer;
