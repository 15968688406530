import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  intro: "",
  address: "",
  city: undefined,
  state: undefined,
  pin: "",
  shopType: "",
  categories: [],
  subCategories: [],
  shopOwnerName: "",
  shopOwnerContactNumber: "",
  shopOwnerCancelledChequeImage: undefined,
  storeFrontPicture: undefined,
  lat: undefined,
  long: undefined,
  capturedAddress: "",
};

const cliamShopSlice = createSlice({
  name: "claim-shop-details",
  initialState,
  reducers: {
    updateCliamShopDetails: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    clearCliamShopDetails: () => initialState,
    updateCliamShopDetailsState: (state, action) => action.payload,
    updateCliamShopDetailsMultipleKeys: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const {
  updateCliamShopDetails,
  clearCliamShopDetails,
  updateCliamShopDetailsState,
  updateCliamShopDetailsMultipleKeys,
} = cliamShopSlice.actions;

export default cliamShopSlice.reducer;
