import Label from "../label/label";

const Input = ({ label, name, value, type, onChange, ...rest }) => {
  return (
    <div className="w-full">
      <Label>{label}</Label>
      <div className="w-full">
        <input
          name={name}
          value={value}
          type={type}
          onChange={onChange}
          className="w-full border rounded-lg py-2 px-3 font-normal text-sm"
          {...rest}
        />
      </div>
    </div>
  );
};

export default Input;
