import { useState, useEffect, useCallback } from "react";
import AxiosInstance from "../lib/axios/axios-instance";
import { toast } from "react-toastify";

const axiosInstance = AxiosInstance.getInstance();

const useFetch = ({ url, params }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getData = useCallback(async () => {
    try {
      const response = await axiosInstance.get(url, params);

      setData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setData(null);
      setError(error);
      setIsLoading(false);
    }
  }, [url]);

  useEffect(() => {
    getData();

    return () => {
      setData(null);
      setError(null);
      setIsLoading(true);
    };
  }, [getData, url]);

  return {
    data,
    isLoading,
    error,
    reload: () => getData(),
  };
};

export default useFetch;
