import "./button.scss";

const Button = ({
  children,
  disabled,
  bgColor = "rgb(255, 152, 0)",
  textColor = "white",
  onClick,
}) => {
  if (disabled) {
    bgColor = "#f3f3f4";
    textColor = "#8D8A95";
  }

  return (
    <button
      style={{ backgroundColor: bgColor, color: textColor }}
      className="ui-btn font-normal text-sm rounded-lg py-2 px-3 border min-w-32 h-full w-full"
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
