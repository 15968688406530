import { useState, useEffect, useCallback } from "react";
import SearchInput from "../../components/search-input/search-input";
import NotFoundIcon from "../../assets/not-found.svg";
import Button from "../../components/button/button";
import CenterComponent, {
  Loader,
} from "../../components/center-component/center-component";
import Body from "../../components/body/body";
import { Heading } from "../../components/headings/headings";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SearchResult from "../../components/search-result/search-result";
import SavedShop from "../../components/saved-shop/saved-shop";
import AxiosInstance from "../../lib/axios/axios-instance";
import { useDispatch } from "react-redux";
import { updateLocation } from "../../lib/redux/slices/location.slice";
import { useLocation } from "../../hooks/use-location";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import Subscribe from "../subscribe-dummy/subscribe-dummy";

const axiosInstance = AxiosInstance.getInstance();

const Search = ({ onAdvanceSearch, searchText, setSearchText }) => {
  return (
    <div className="flex justify-between border border-t-0 border-l-0 border-r-0 sm:py-4 sm:px-6 gap-3 px-2 py-2">
      <SearchInput searchText={searchText} setSearchText={setSearchText} />
      <div>
        <button
          onClick={onAdvanceSearch}
          className="py-2 pr-2 pl-1.5 flex justify-center items-center font-normal rounded-lg border text-[#4F4B5C] h-8 text-xs w-28"
        >
          <p>Advance Search</p>
        </button>
      </div>
    </div>
  );
};

const ShopNotFound = ({ isSearch }) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/instructions");
  };

  return (
    <div className="flex justify-center items-center w-full min-h-[64.5vh]">
      <div>
        <div className="flex flex-col gap-2">
          <div className="flex justify-center items-center">
            <img src={NotFoundIcon} alt="not-found-icon" />
          </div>
          <p className="font-semibold text-lg text-[#110c22] text-center">
            {isSearch
              ? "No shop found basis on your search."
              : "No shop found."}
          </p>
          <div className="flex justify-center items-center">
            <div className="w-36">
              <Button disabled={false} onClick={onClick}>
                Add My Shop
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ShowSearchResult = ({ dataList, error, isLoading, isSearch }) => {
  if (isLoading) {
    return (
      <CenterComponent>
        <Loader />
      </CenterComponent>
    );
  }

  if (error) {
    return <CenterComponent>{error.message}</CenterComponent>;
  }

  if (dataList.length === 0) {
    return <ShopNotFound isSearch={isSearch} />;
  }

  return (
    <SearchResult
      heading={
        isSearch ? `Result: ${dataList.length} found` : `Not Claimed / Claimed`
      }
      dataList={dataList}
    />
  );
};

const Dashboard = () => {
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const [searchPage, setSearchPage] = useState(1);
  const [hasMoreSearch, setHasMoreSearch] = useState(false);

  const savedShop = useSelector((store) => store.savedShop);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const { isLocating, location, error: locationError } = useLocation();

  const getLocationDetal = useCallback(() => {
    if (!location) {
      return;
    }

    try {
      dispatch(
        updateLocation({
          latitude: location.latitude,
          longitude: location.longitude,
        })
      );
    } catch (err) {
      console.error(err);
    }
  }, [dispatch, location]);

  const getData = async () => {
    try {
      const response = await axiosInstance.get("/claim-shops/filter", {
        params: {
          page: page
        },
      });

      setIsSearch(false);
      setSearchResult([]);
      setIsLoading(false);
      setHasMore(!!response.data?.links?.next);

      if (!response.data.items.length) return;
      setData(data ? [...data, ...response.data.items] : response.data.items);
    } catch (error) {
      console.error(error);
      setData(null);
      setError(error);
      setIsLoading(false);
      toast.dismiss();
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, [page]);

  useEffect(() => {
    getLocationDetal(location);
  }, [location, getLocationDetal]);

  useEffect(() => {
    onAdvanceSearch();
  }, [searchPage]);

  const onAdvanceSearch = async () => {
    if (searchText.trim() === "") {
      setSearchResult([]);
      setIsSearch(false);
      return;
    }

    try {
      const response = await axiosInstance.get("/claim-shops/filter", {
        params: {
          name: searchText,
          page: searchPage
        },
      });

      setIsSearch(true);
      setHasMoreSearch(!!response.data?.links?.next);

      if (!response.data.items.length) return;
      setSearchResult(
        searchResult
          ? [...searchResult, ...response.data.items]
          : response.data.items
      );
    } catch (err) {
      console.error(err);
    }
  };

  const nextPage = async () => {
    if (!hasMore) return;

    setPage((page) => Number(page) + 1);
  };

  const nextPageSearch = async () => {
    if (!hasMoreSearch) return;

    setSearchPage((page) => Number(page) + 1);
  };

  return (
    <Body>
      <div>
        <Heading>Claim Shop</Heading>
        <div className="border border-b-0 rounded-tr-2xl rounded-tl-2xl mt-4">
          <div className="flex items-center px-2 py-2 sm:py-3 sm:px-6 rounded-tr-2xl rounded-tl-2xl bg-[#f8f8f8]">
            <div className="text-[#4f4b5c] w-full text-sm font-normal md:w-2/3">
              Shop Name
            </div>
            <div className="text-[#4f4b5c] w-2/3 md:w-[34%] text-sm font-normal">
              Status
            </div>
          </div>
          <Search
            onAdvanceSearch={onAdvanceSearch}
            searchText={searchText}
            setSearchText={setSearchText}
          />


          {savedShop.isSaved && (
            <SavedShop details={savedShop.savedShopDetails} heading="Save" />
          )}

          {isSearch ? (
            <InfiniteScroll
              dataLength={searchResult?.length || 0}
              next={nextPageSearch}
              hasMore={hasMoreSearch}
              loader={<Loader />}
              endMessage={
                !!searchResult?.length && (
                  <p className="text-center text-gray-500 py-6">
                    No more data!
                  </p>
                )
              }
            >
              <ShowSearchResult
                isLoading={isLoading}
                dataList={searchResult}
                error={error}
                isSearch={isSearch}
              />
            </InfiniteScroll>
          ) : (
            <InfiniteScroll
              dataLength={data?.length || 0}
              next={nextPage}
              hasMore={hasMore}
              loader={<Loader />}
            >
              <ShowSearchResult
                isLoading={isLoading}
                dataList={data || []}
                error={error}
                isSearch={isSearch}
              />
            </InfiniteScroll>
          )}


        </div>
      </div>
    </Body>
  );
};

export default Dashboard;
