import { Heading } from "../../components/headings/headings";
import Body from "../../components/body/body";

const PrivacyPolicy = () => {
  return (
    <Body>
      <div>
        <Heading>Privacy Policy</Heading>
        <div className="flex flex-col gap-5 mt-5">
          <div className="font-semibold text-lg">Dodong Pvt Ltd.</div>
          <div className="font-semibold text-lg">
            Effective Date: 5 May 2024
          </div>

          <div>
            <ul className="list-none pl-3">
              <li className="font-semibold text-sm">
                1. Information We Collect
              </li>
              <li>a. Information You Provide to Us:</li>
              <ul className="list-disc pl-10">
                <li>
                  <b className="font-medium">Account Information:</b> When you
                  create an account, we collect information such as your email
                  address, username, and password.
                </li>
                <li>
                  <b className="font-medium">Profile Information:</b>{" "}
                  Information you choose to include in your profile, including
                  your name, photo, and any other details.
                </li>
                <li>
                  <b className="font-medium">Content Information:</b>{" "}
                  Information relating to the content you post, such as photos,
                  videos, comments, and other materials.
                </li>
                <li>
                  <b className="font-medium">Communications:</b> Information you
                  provide when you communicate with us or other users through
                  the Service.
                </li>
              </ul>
              <li>
                b. Information Collected Automatically:
                <ul className="list-disc pl-10">
                  <li>
                    <b className="font-medium">Device Information:</b> When you
                    create an account, we collect information such as your email
                    address, username, and password.
                  </li>
                  <li>
                    <b className="font-medium">Usage Data:</b> Details about how
                    you interact with the Service, including the pages you
                    visit, the features you use, and the time spent on those
                    pages.
                  </li>
                  <li>
                    <b className="font-medium">Location Information:</b>
                    With your consent, we may collect precise location data to
                    offer location-based services.
                  </li>
                </ul>
              </li>
              <li>
                c. Information from Third Parties:{" "}
                <p>
                  {" "}
                  We may receive information about you from third parties, such
                  as social media platforms, in accordance with their privacy
                  policies.
                </p>
              </li>
              <li>
                2. How We Use Your Information
                <p> We use the information we collect to:</p>
                <ul className="list-disc pl-10">
                  <li>Provide, maintain, and improve the Service;</li>
                  <li>Personalize your experience;</li>
                  <li>
                    Communicate with you about the Service, including updates
                    and customer support;
                  </li>
                  <li>Enhance the safety and security of the Service;</li>
                  <li>
                    Conduct research and analysis to understand our users
                    better.
                  </li>
                </ul>
              </li>
              <li>
                3. Sharing Your Information:
                <p> We may share your information with:</p>
                <ul className="list-disc pl-10">
                  <li>
                    <b className="font-medium">Service Providers:</b> Third
                    parties who perform services on our behalf, such as hosting,
                    analytics, and marketing services.
                  </li>
                  <li>
                    <b className="font-medium">Affiliates:</b> Entities within
                    the Dodong corporate group.
                  </li>
                  <li>
                    <b className="font-medium">Legal Requirements:</b> When
                    required by law or to protect the rights, property, or
                    safety of Dodong, our users, or others.
                  </li>
                  <li>
                    <b className="font-medium">Business Transfers:</b> In
                    connection with a merger, acquisition, or sale of
                  </li>
                </ul>
              </li>
              <li>
                <p className="font-semibold text-sm">
                  4. Your Rights and Choices
                </p>{" "}
                Depending on your location, you may have certain rights
                regarding your personal information, including the right to
                access, correct, or delete your information. You can manage your
                information through the account settings within the Service.
              </li>
              <li>
                <p className="font-semibold text-sm">
                  5. International Transfers
                </p>{" "}
                Your information may be transferred to and processed in
                countries other than your own, where data protection laws may
                differ. We will take all necessary measures to ensure that your
                information is protected.
              </li>
              <li>
                <p className="font-semibold text-sm">6. Security Measures</p> We
                implement reasonable security measures to protect your
                information from unauthorized access, alteration, disclosure, or
                destruction.
              </li>
              <li>
                <p className="font-semibold text-sm">
                  7. Changes to This Privacy Policy
                </p>{" "}
                We may update this Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                the Service and updating the &quot;Effective Date&quot; above.
              </li>
              <li>
                <p className="font-semibold text-sm">8. Contact Us</p> If you
                have any questions about this Privacy Policy, please contact us
                at{" "}
                <a href="mailto:dodong.business@gmail.com">
                  dodong.business@gmail.com
                </a>
                . By using our Service, you consent to our collection, use, and
                sharing of your information as described in this Privacy Policy.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Body>
  );
};

export default PrivacyPolicy;
