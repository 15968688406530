import { useDispatch, useSelector } from "react-redux";
import CenterComponent, { Loader } from "../../components/center-component/center-component";
import SimilarProducts from "../similar-products/similar-products";
import SimilarShops from "../similar-shops/similar-shops";
import { useCallback, useEffect } from "react";
import { updateLocation } from "../../lib/redux/slices/location.slice";
import useFetch from "../../hooks/use-fetch.hook";
import { useLocation, useParams } from "react-router-dom";
import Body from "../../components/body/body";

const DUMMY_SHOP = {
    "id": 1,
    "createdAt": "2024-05-15T04:03:12.871Z",
    "updatedAt": "2024-05-15T04:03:13.712Z",
    "name": "Wooden Street - Furniture 1 ",
    "intro": "qwe",
    "address": "No.163/1, 2nd Floor, BLOCK-Z1, No.201, BRT Road, above Sakti Sports Office, Roseland ",
    "city": "Shimla",
    "state": "Himachal Pradesh",
    "pin": "171010",
    "shopType": "Online Retailers",
    "shopOwnerName": "Test Test",
    "shopOwnerContactNumber": "+916230795693",
    "shopOwnerCancelledChequeImage": "https://premium-section-uploads.s3.ap-south-1.amazonaws.com/1-cheque",
    "storeFrontPicture": "https://premium-section-uploads.s3.ap-south-1.amazonaws.com/1-store-picture",
    "lat": 31.6861745,
    "long": 76.5213092,
    "capturedAddress": "MGPC+FG7 Hamirpur, Himachal Pradesh, India",
    "isClaimed": false,
    "metaInfo": {},
    "categories": [
        {
            "id": 1,
            "createdAt": "2024-05-15T02:53:12.033Z",
            "updatedAt": "2024-05-15T02:53:12.033Z",
            "label": "Food",
            "subCategories": [
                {
                    "id": 2,
                    "createdAt": "2024-05-15T02:53:56.248Z",
                    "updatedAt": "2024-05-15T02:53:56.248Z",
                    "label": "North Indian",
                    "categoryId": 1
                },
                {
                    "id": 8,
                    "createdAt": "2024-05-15T03:00:19.949Z",
                    "updatedAt": "2024-05-15T03:00:19.949Z",
                    "label": "South Indian",
                    "categoryId": 1
                }
            ]
        },
        {
            "id": 2,
            "createdAt": "2024-05-15T03:10:18.665Z",
            "updatedAt": "2024-05-15T03:10:18.665Z",
            "label": "Car",
            "subCategories": [
                {
                    "id": 9,
                    "createdAt": "2024-05-15T03:10:29.681Z",
                    "updatedAt": "2024-05-15T03:10:29.681Z",
                    "label": "Sports",
                    "categoryId": 2
                }
            ]
        }
    ],
    "subCategories": [
        {
            "id": 9,
            "createdAt": "2024-05-15T03:10:29.681Z",
            "updatedAt": "2024-05-15T03:10:29.681Z",
            "label": "Sports",
            "categoryId": 2
        },
        {
            "id": 2,
            "createdAt": "2024-05-15T02:53:56.248Z",
            "updatedAt": "2024-05-15T02:53:56.248Z",
            "label": "North Indian",
            "categoryId": 1
        }
    ]
}

const Demo = () => {
  let { shopId } = useParams();
  // const { isLoading, error, reload } = useFetch({
  //   url: `/claim-shops/${shopId}`,
  // }); // get Data from network request
  const { isLocating, location, error: locationError } = useLocation();
  
  const data = DUMMY_SHOP //  remove this Data to get response from Network

  const dispatch = useDispatch();

  const getLocationDetal = useCallback(() => {
    if (!location) {
      return;
    }

    try {
      dispatch(
        updateLocation({
          latitude: location.latitude,
          longitude: location.longitude,
        })
      );
    } catch (err) {
      console.error(err);
    }
  }, [dispatch, location]);

  useEffect(() => {
    getLocationDetal(location);
  }, [location, getLocationDetal]);

  const locationDetails = useSelector((state) => state.location);

  // if (isLoading) {
  //   return (
  //     <CenterComponent>
  //       <Loader />
  //     </CenterComponent>
  //   );
  // }

  // if (!data) {
  //   return <CenterComponent>No Shop data available.</CenterComponent>;
  // }

  // if (error) {
  //   return <CenterComponent>{error}</CenterComponent>;
  // }

  const {
    id,
    categories,
    subCategories
  } = data;
  const currLatitude = locationDetails?.latitude;
  const currLongitude = locationDetails?.longitude;

  return (
    <Body>
      <SimilarProducts
        currLatitude={currLatitude}
        currLongitude={currLongitude}
        categories={categories}
        subCategories={subCategories}
        shopId={id}
      />

      <SimilarShops
        currLatitude={currLatitude}
        currLongitude={currLongitude}
        categories={categories}
        subCategories={subCategories}
        shopId={id}
      />

    </Body>
  )
}

export default Demo