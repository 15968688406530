export const Heading = ({ children }) => {
  return (
    <div>
      <p className="font-semibold text-2xl leading-6 m-0 p-0">{children}</p>
    </div>
  );
};

export const SubHeading = ({ children }) => {
  return <p className="font-light text-sm text-[#8D8A95]">{children}</p>;
};
