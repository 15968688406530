import BadgeIcon from "../../assets/badge.svg";

const VerifiedBadge = () => (
  <div
    style={{
      backgroundColor: "rgb(255, 152, 0)",
      color: "white",
    }}
    className="ui-btn font-normal text-sm rounded-lg py-2 px-3 border min-w-32 h-full w-full"
  >
    <div className="flex gap-1 items-center justify-center">
      <div>
        <img src={BadgeIcon} alt="badge" />
      </div>
      <div>Verified</div>
    </div>
  </div>
);

export default VerifiedBadge;
