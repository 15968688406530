import { Heading } from "../../components/headings/headings";
import Body from "../../components/body/body";

const TermsAndConditions = () => {
  return (
    <Body>
      <div>
        <Heading>Terms and Conditions for Dodong</Heading>
        <div className="flex flex-col gap-5 mt-5">
          <div className="font-semibold text-lg">
            Welcome to Dodong, a dynamic platform designed to foster creativity
            and connection through the sharing of images and videos. By
            accessing and using Dodong&#39;s services, you agree to these Terms
            and Conditions, entering a binding contract with Dodong Pvt. Ltd.
            (&quot;we&quot;, &quot;us&quot;, or &quot;our&quot;).
          </div>

          <div>
            <ul className="list-none pl-3">
              <li>
                <p className="font-semibold text-sm">1. Acceptance of Terms</p>
                <p>
                  By using any part of the Dodong service, including the mobile
                  application, website, and any software provided by us
                  (collectively, the &quot;Service&quot;), you understand and
                  agree to these Terms and Conditions, our Privacy Policy, and
                  any other legal notices published by us on the platform.
                </p>
              </li>
              <li>
                <p className="font-semibold text-sm">2. Changes to Terms</p>
                <p>
                  We reserve the right to modify these terms at any time. We
                  will notify you of significant changes and your continued use
                  of the Service after such changes will constitute acceptance.
                </p>
              </li>
              <li>
                <p className="font-semibold text-sm"> 3. User Eligibility</p>
                <p>
                  The Service is available to individuals who are at least 13
                  years old. If you are under 18, you are representing that you
                  have the consent of your parent or guardian to use the
                  Service.
                </p>
              </li>
              <li>
                <p className="font-semibold text-sm">
                  4. Account Use and Responsibilities
                </p>
              </li>
              <ul className="list-disc pl-10">
                <li>
                  <b className="font-medium">Account Creation:</b> You must
                  provide accurate, current, and complete information during the
                  registration process.
                </li>
                <li>
                  <b className="font-medium">Account Security:</b> You are
                  responsible for safeguarding the password and for all
                  activities that occur under your account.
                </li>
                <li>
                  <b className="font-medium">User Content:</b>
                  You own the content you create, but you grant us a global,
                  non- exclusive, royalty-free license to use, copy, reproduce,
                  process, adapt, modify, publish, transmit, display, and
                  distribute such content through any media.
                </li>
              </ul>
              <li>
                <p className="font-semibold text-sm">5. Content Standards</p>
                <p>You agree not to post content that:</p>
              </li>
              <ul className="list-disc pl-10">
                <li>Violates any laws or regulations.</li>
                <li>
                  Is harmful, fraudulent, deceptive, threatening, harassing,
                  defamatory, obscene, or otherwise objectionable.
                </li>
                <li>Infringes on any intellectual property rights.</li>
              </ul>
              <li>
                <p className="font-semibold text-sm">
                  6. Intellectual Property
                </p>
                <p>You agree not to post content that:</p>
              </li>
              <ul className="list-disc pl-10">
                <li>
                  <b className="font-medium">Our Rights:</b>
                  All rights, title, and interest in and to the Service
                  (excluding User Content) are and will remain the exclusive
                  property of Dodong and its licensors.
                </li>
                <li>
                  <b className="font-medium">Your Rights:</b> Dodong does not
                  claim any ownership rights to the content you upload to the
                  Service.
                </li>
              </ul>

              <li>
                <p className="font-semibold text-sm">7. Privacy</p>
                <p>
                  {" "}
                  Our collection and use of personal information in connection
                  with the Service are as provided in Dodong&#39;s Privacy
                  Policy.
                </p>
              </li>

              <li>
                <p className="font-semibold text-sm">8. Termination</p>
                <p>
                  We may terminate or suspend your account and bar access to the
                  Service immediately, without prior notice, under our sole
                  discretion, for any reason whatsoever and without limitation,
                  including but not limited to a breach of the Terms.
                </p>
              </li>

              <li>
                <p className="font-semibold text-sm">9. Disclaimers</p>
                <p>
                  The Service is provided on an &quot;AS IS&quot; and &quot;AS
                  AVAILABLE&quot; basis. Dodong expressly disclaims any
                  warranties of any kind, whether express or implied, including,
                  but not limited to, the implied warranties of merchantability,
                  fitness for a particular purpose, and non-infringement.
                </p>
              </li>

              <li>
                <p className="font-semibold text-sm">
                  10. Limitation of Liability
                </p>
                <p>
                  Dodong shall not be liable for any indirect, incidental,
                  special, consequential, or punitive damages, including without
                  limitation, loss of profits, data, use, goodwill, or other
                  intangible losses.
                </p>
              </li>

              <li>
                <p className="font-semibold text-sm">11. Governing Law</p>
                <p>
                  These Terms shall be governed by the laws of Country of
                  operation, without regard to its conflict of law provisions.
                </p>
              </li>

              <li>
                <p className="font-semibold text-sm">12. Dispute Resolution</p>
                <p>
                  Any disputes arising out of or in connection with this
                  Agreement shall be resolved through binding arbitration in
                  accordance with the rules in Country of operation.
                </p>
              </li>

              <li>
                <p className="font-semibold text-sm">13. General Terms</p>
              </li>

              <ul className="list-disc pl-10">
                <li>
                  <b className="font-medium">Entire Agreement:</b>
                  These Terms constitute the entire agreement between you and
                  Dodong regarding the Service.
                </li>
                <li>
                  <b className="font-medium">Severability:</b> If any provision
                  of these Terms is held to be invalid or unenforceable, that
                  part shall be enforced to the maximum extent possible, and the
                  other provisions will remain in full force and effect.
                </li>
              </ul>

              <li>
                <p className="font-semibold text-sm">14. Contact Information</p>
                <p>
                  For any questions about these Terms, please contact us at{" "}
                  <a href="mailto:dodong.business@gmail.com">
                    dodong.business@gmail.com
                  </a>
                  . By using Dodong, you agree to be bound by these Terms and
                  Conditions.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Body>
  );
};

export default TermsAndConditions;
