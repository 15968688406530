import SearchIcon from "../../assets/Search.svg";

const SearchInput = ({ setSearchText, searchText }) => {
  return (
    <div className="flex w-full relative">
      <div className="absolute pl-2.5 pt-1.5">
        <img src={SearchIcon} alt="search-icon" />
      </div>
      <div className="w-full">
        <input
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          className="w-full py-1 pr-0 pl-10 m-0 rounded-lg h-full border"
          placeholder="Search shop"
        />
      </div>
    </div>
  );
};

export default SearchInput;
