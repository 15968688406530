import { Heading } from "../../components/headings/headings";
import Body from "../../components/body/body";

const PricingPolicy = () => {
  return (
    <Body>
      <div>
        <Heading>Dodong Premium Pricing Policy</Heading>
        <div className="flex flex-col gap-1 mt-5">
          <div className="font-semibold text-xl">
            Annual Subscription Fee: ₹ 1999/year
          </div>





          <div className="font-semibold text-lg">
                What’s Included:
          </div>
          <div className="pl-5">
              

            <div className="font-semibold">
                1. Priority Placement:
            </div>  
            <ul className="pl-10 list-disc">
              <li>Your shop receives top visibility in Dodong’s search results.</li>
              <li>Benefit from being the first choice for potential customers seeking local businesses.</li>
            </ul>


            <div className="font-semibold">
                2. Exclusive Features:
            </div> 
            <ul className="list-disc pl-10">
              <li><span className="font-semibold">Advanced Promotional Tools:</span> Tailor your marketing strategies with precision.</li>
              <li><span className="font-semibold">Comprehensive Analytics:</span>  Dive deep into customer behavior, sales trends, and performance metrics.</li>
              <li><span className="font-semibold">Inventory Management:</span>  Streamline stock control effortlessly.</li>
            </ul>



            <div className="font-semibold">
                3. Cash Discounts:
            </div> 
            <ul className="list-disc pl-10">
              <li><span className="font-semibold">Incentivize Shoppers:</span> Offer exclusive discounts to drive sales and encourage repeat business.</li>
              <li><span className="font-semibold">Boost Customer Loyalty:</span> Reward loyal customers and attract new </li>
            </ul>

            
             <div className="font-semibold">
                4. Verified Badge:
            </div> 
            <ul className="list-disc pl-10">
              <li><span className="font-semibold">Credibility and Trust:</span> Display the verified badge to assure customers of your reliability.</li>
              <li><span className="font-semibold">Stand Out:</span> Gain an edge over competitors by showcasing your trustworthy status.</li>
            </ul>

            <div className="font-semibold">
                5. Dedicated Support:
            </div> 
            <ul className="list-disc pl-10">
              <li><span className="font-semibold">Personalized Assistance:</span> Our support team is committed to your success.</li>
              <li><span className="font-semibold">Prompt Resolution:</span> Get timely help with any queries or technical issues.</li>
            </ul>
          </div>

          <div>
            <div className="font-semibold text-lg">
              Transparent Renewal:
            </div>
            <ul className="list-disc pl-10">
              <li><span className="font-semibold">Renew Annually:</span> Your subscription remains at the same rate—no surprises.</li>
              <li><span className="font-semibold">Honest Pricing:</span> We believe in transparency and fair business practices.</li>
            </ul>
          </div>

          <div>
            <div className="font-semibold text-lg">
              30-Day Money-Back Guarantee:
            </div>
            <ul className="list-disc pl-10">
              <li>If you’re not satisfied within the first 30 days, we’ll refund your subscription.</li>
              <li>No questions asked.</li>
            </ul>
          </div>



          <div className="font-semibold text-lg">
            Legal Disclaimer:
          </div>
          <ul className="text-lg list-disc pl-10">
             <li>Dodong Premium’s pricing policy is subject to change, but any modifications will be communicated in advance.</li>
             <li>By subscribing to Dodong Premium, you agree to our terms of service and privacy policy.</li>
          </ul>

          <div className="text-lg">Join Dodong Premium today and elevate your business with confidence! 🌟</div>
        </div>
      </div>
    </Body>
  );
};

export default PricingPolicy;
