import { Heading } from "../../components/headings/headings";
import Body from "../../components/body/body";

const RefundAndCancellation = () => {
  return (
    <Body>
      <div>
        <Heading>Dodong Pvt Ltd.</Heading>
        <div className="flex flex-col gap-5 mt-5">
          <div className="font-semibold text-lg">
            Cancellation and Refund Policy
          </div>
          <div className="font-semibold text-lg">
            Effective Date: 5 May 2024
          </div>
          <div className="font-semibold text-lg">
            Welcome to Dodong! We aim to ensure that your experience with our
            platform is satisfying and rewarding. However, we understand that
            there may be instances where you may need to cancel your service or
            request a refund. This policy provides information on our
            cancellation and refund process to help you understand your rights
            and obligations.
          </div>

          <div>
            <ul className="list-none pl-3">
              <li>
                <p className="font-semibold text-sm">
                  1. Cancellation of Service
                </p>
                <p>a. Voluntary Cancellation</p>
                <p>
                  {" "}
                  You may cancel your use of the Dodong service at any time via
                  the account settings page on our website or app. Upon
                  cancellation, your account will be deactivated, and you will
                  no longer be billed from the next billing cycle.
                </p>
              </li>

              <li>
                <p className="font-semibold text-sm">
                  b. Involuntary Cancellation
                </p>
                <p>
                  Dodong reserves the right to suspend or terminate your account
                  for any reason deemed appropriate, including but not limited
                  to a breach of our Terms and Conditions, without notice. In
                  such cases, Dodong will determine, at its discretion, whether
                  a refund is appropriate.
                </p>
              </li>

              <li>
                <p className="font-semibold text-sm">2. Refund Policy</p>
                <p className="font-semibold text-sm">
                  a. Subscription Services
                </p>
                <p>
                  If you have subscribed to any of Dodong&#39;s paid services,
                  you may cancel within 5 days from the start of your
                  subscription for a full refund. After this period, we do not
                  offer refunds or credits for partial subscription periods or
                  unwatched Dodong content.
                </p>
              </li>

              <li>
                <p className="font-semibold text-sm">b. One-time Purchases</p>
                <p>
                  For any one-time purchase of services or content, you can
                  request a refund within 5 days of your transaction if you are
                  not satisfied or if there is a defect in the service or
                  content provided.
                </p>
              </li>

              <li>
                <p className="font-semibold text-sm">c. Exceptions</p>
                <p>
                  Some special circumstances may warrant exceptions to this
                  policy at our discretion. These may include technical issues
                  that prevent access to the service or errors in billing.
                </p>
              </li>

              <li>
                <p className="font-semibold text-sm">3. Processing Refunds</p>
                <p>
                  Refunds will be processed to the original method of payment.
                  Please allow up to 5 working days for the refund to be
                  reflected in your account. For payments made using other
                  methods, refunds will be processed through an alternative
                  method agreed upon by both parties.
                </p>
              </li>

              <li>
                <p className="font-semibold text-sm">
                  4. International Considerations
                </p>
                <p>
                  Please note that international refund requests may be subject
                  to exchange rate fluctuations, bank charges, and any
                  applicable taxes. Dodong cannot be held responsible for any
                  discrepancies in refund amounts due to these factors.
                </p>
              </li>

              <li>
                <p className="font-semibold text-sm"> 5. Contact Us</p>
                <p>
                  If you have any questions about our Cancellation and Refund
                  Policy or wish to request a refund, please contact us at{" "}
                  <a href="mailto:dodong.business@gmail.com">
                    dodong.business@gmail.com
                  </a>
                  .
                </p>
                <p>
                  By using our services, you agree to this Cancellation and
                  Refund Policy. Dodong reserves the right to modify this policy
                  at any time, and changes will be effective immediately upon
                  posting the revised policy.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Body>
  );
};

export default RefundAndCancellation;
