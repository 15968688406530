import { useState, useEffect } from "react";

export function useLocation() {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);
  const [isLocating, setIsLocating] = useState(true);

  useEffect(() => {
    function geoLocation() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setError(null);
          setIsLocating(false);
        },
        (error) => {
          console.log(error);
          setError(error);
          setIsLocating(false);
          setLocation(null);
        },
        {
          enableHighAccuracy: true,
        }
      );
    }

    if ("geolocation" in navigator) {
      geoLocation();
    } else {
      setError({
        message: "Location API not available in the device.",
      });
      setIsLocating(false);
      setLocation(null);
    }

    return function cleanup() {
      setLocation(null);
      setError(null);
    };
  }, []);

  return { isLocating, location, error };
}
