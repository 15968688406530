import Button from "../button/button";
import DummyImage from "../../assets/dummy.png";
import LocationIcon from "../../assets/Location.svg";
import { getDistanceBetweenTwoLatLonInKm } from "../../utils/calulate-distance";
import { SaveBadge } from "../badge/badge";
import { useSelector, useDispatch } from "react-redux";
import { updateCliamShopDetailsState } from "../../lib/redux/slices/claim-shop.slice";
import { useNavigate } from "react-router-dom";
import { CardHeading } from "../card-heading/card-heading";

const SavedCard = ({ details }) => {
  const { lat, long, storeFrontPicture, name } = details;
  const location = useSelector((store) => store.location);
  const currLatitude = location?.latitude;
  const currLongitude = location?.longitude;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const savedShopDetails = useSelector((state) => state.savedShop);

  const continueClaim = () => {
    dispatch(updateCliamShopDetailsState(savedShopDetails.savedShopDetails));
    navigate("/add-shop");
  };

  return (
    <div className="flex gap-3 sm:gap-0 flex-col justify-center sm:flex-row sm:items-center sm:justify-between sm:px-6 sm:py-3 border-b px-2 py-2">
      <div className="flex w-full justify-center sm:justify-between">
        <div className="flex items-center w-3/6 sm:w-1/2">
          <div className="h-16 w-16 rounded-xl overflow-hidden">
            <img
              className="h-full w-full"
              src={storeFrontPicture ? storeFrontPicture : DummyImage}
              alt="shop-logo"
            />
          </div>
          <div className="ml-5">
            <div className="font-semibold text-sm">
              {name ? name : "Your shop name here..."}
            </div>
            {lat && long && currLatitude && currLongitude && (
              <div className="flex items-center">
                <img
                  className="w-2.5 h-3"
                  src={LocationIcon}
                  alt="location-icon"
                />
                <span className="ml-1 font-normal text-xs text-[#8D8A95]">
                  {getDistanceBetweenTwoLatLonInKm(
                    lat,
                    long,
                    currLatitude,
                    currLongitude
                  )}{" "}
                  km
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="w-3/6 sm:w-3/12 flex justify-center items-center">
          <div className="w-fit">
            <SaveBadge />
          </div>
        </div>
      </div>

      <div className="w-full sm:w-3/12 flex items-center justify-end">
        <div className="w-full sm:w-32">
          <Button onClick={continueClaim}>Continue Claim</Button>
        </div>
      </div>
    </div>
  );
};

const SavedShop = ({ heading, details }) => {
  return (
    <div>
      <div>
        <div className="pt-7">
          <CardHeading heading={heading} />
          <div>
            <SavedCard details={details} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavedShop;
