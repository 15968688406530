import CloseIcon from "../../assets/close.svg";

// export const BackgroundImageSection = ({ imgUrl }) => {
//   const imgStyles = {
//     backgroundImage: `URL(${imgUrl})`,
//   };
//   return (
//     <div
//       className="bg-center bg-cover bg-no-repeat h-[inherit] w-[inherit]"
//       style={imgStyles}
//     />
//   );
// };

export const BackgroundImageSection = ({ imgUrl }) => {
  return <img className="h-[inherit] w-[inherit] max-width-unset" src={imgUrl} alt="img" />;
};

const ImageSection = ({ imgUrl, onBtnClick, btnImgUrl = CloseIcon }) => {
  return (
    <div className="h-[inherit] w-[inherit] overflow-hidden relative">
      <div className="absolute right-3 top-3">
        <button
          className="bg-[#000000BF] border rounded-lg p-1"
          onClick={onBtnClick}
        >
          <img src={btnImgUrl} alt="btn-icon" />
        </button>
      </div>
      <BackgroundImageSection imgUrl={imgUrl} />
    </div>
  );
};

export default ImageSection;
