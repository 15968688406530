export const ShowOptions = ({ options }) => {
  if (!options) return null;
  return options.map((option) => (
    <option key={option} value={option}>
      {option}
    </option>
  ));
};

const SelectInputOnly = ({ options, value, onChange, name }) => {
  return (
    <select
      name={name}
      value={value}
      onChange={onChange}
      className="w-full border rounded-lg py-2 px-3 font-normal text-sm"
    >
      <option value={""}></option>
      <ShowOptions options={options} />
    </select>
  );
};

export default SelectInputOnly;
