import SaveIcon from "../../assets/save-brown.svg";
import NotClaimedIcon from "../../assets/security.svg";
import ClaimedIcon from "../../assets/claimed.svg";

const Badge = ({
  text,
  icon,
  textColor = "#9E5E25",
  borderColor = "#FDD9B8",
  bgColor = "#FEEEDF",
}) => {
  let className =
    "flex justify-between items-center border rounded-lg py-1 pl-1.5 pr-2";
  return (
    <div
      style={{
        backgroundColor: bgColor,
        color: textColor,
        borderColor: borderColor,
      }}
      className={className}
    >
      <img src={icon} alt="tag-icon" />
      <span style={{ color: textColor }} className="ml-1 font-normal text-xs">
        {text}
      </span>
    </div>
  );
};

export const SaveBadge = () => {
  return (
    <Badge
      icon={SaveIcon}
      text="Save"
      textColor="#9E5E25"
      borderColor="#FDD9B8"
      bgColor="#FEEEDF"
    />
  );
};

export const ClaimedBadge = () => {
  return (
    <Badge
      icon={ClaimedIcon}
      text="Claimed"
      textColor="#4F4B5C"
      borderColor="#ECECED"
      bgColor="#F8F8F8"
    />
  );
};

export const NotClaimedBadge = () => {
  return (
    <Badge
      icon={NotClaimedIcon}
      text="Not Claimed"
      textColor="#4F4B5C"
      borderColor="#ECECED"
      bgColor="transparent"
    />
  );
};

export default Badge;
