import { useEffect, useState, useCallback, useRef } from "react";
import AxiosInstance from "../../lib/axios/axios-instance";
import LocationLightIcon from "../../assets/location-light.svg";
import {
  BackgroundImageSection,
} from "../../components/image-section/image-section";
import LocationIcon from "../../assets/Location.svg";
import SmallArrowRightIcon from "../../assets/small-arrow-right.svg";
import SmallArrowLeftIcon from "../../assets/small-arrow-left.svg";
import CenterComponent, {
  Loader,
} from "../../components/center-component/center-component";
import { getDistanceBetweenTwoLatLonInKm } from "../../utils/calulate-distance";
import { toast } from "react-toastify";
import { DUMMY_DATA } from "../similar-products/similar-products"

const axiosInstance = AxiosInstance.getInstance();

const SimilarShopsCard = ({width, data, currLatitude, currLongitude }) => {

    const { name, address, lat, long } = data;

  return (
<div style={{width: `${width}px`}}>
  <div style={{width: `${width}px`}} className={`h-44 rounded-tl-2xl rounded-tr-2xl overflow-hidden`}>
    <div className="h-[inherit] w-[inherit] overflow-hidden relative">
      <div className="absolute left-3 bottom-3">
        <div
          className="bg-[#000000BF] border-[#000000] border rounded-lg p-1 flex gap-1 shadow-[#110C2214]"
        >
          
          <div><img src={LocationLightIcon} alt="btn-icon" /></div>
          {(lat && long &&currLatitude && currLongitude) && <div className="text-[#FFFFFF] font-medium text-xs">{getDistanceBetweenTwoLatLonInKm(
                    lat,
                    long,
                    currLatitude,
                    currLongitude
                  )}{" "}km
            </div>}

        </div>
      </div>
      <BackgroundImageSection imgUrl={"https://premium-section-uploads.s3.ap-south-1.amazonaws.com/1-store-picture"} />
    </div>
            </div>
          <div className="h-auto flex flex-col justify-center gap-2 border border-t-0 rounded-bl-2xl rounded-br-2xl py-3 px-4">
              <div>
                    <h3 title={name} className="font-semibold text-sm leading-6 text-[#110C22] truncate">
                      {name}
                    </h3>
              </div>

          <div className="flex gap-2">
              <div className="w-8 h-3">
                <img className="w-8 h-3"  src={LocationIcon} alt="location-icon" />
              </div>
              <div className="flex flex-col gap-1">
                <p className="font-normal text-xs leading-4 text-[#8D8A95]">
                  {address}
                </p>
              </div>
          </div>

      </div>
    </div>)}

const SimilarShops = ({ 
    cardWidth = 212, 
    currLatitude, 
    currLongitude, 
    categories, 
    subCategories, 
    shopId }) => {
  const divRef = useRef(null)
  const speed = 30

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [data, setData] = useState(DUMMY_DATA); // Should be null instead of DUMMY_DATA while getting data from Db
  const [isLoading, setIsLoading] = useState(false); // set true
  const [error, setError] = useState(null);
  const [hasLeftCards, setHasLeftCards] = useState(false);
  const [hasRightCards, setHasRightCards] = useState(true);
  

  const getData = async () => {
    try {
      const response = await axiosInstance.get("/claim-shops/similar-shops", {
        params: {
          categoryIds: categories.map(category => category.id),
          subCategoryIds: subCategories.map(subCategory => subCategory.id),
          page: page,
          currentShopId: shopId
        },
      });


      setIsLoading(false);
      setHasMore(response.data?.links?.next !== "");

      if (!response.data.items.length) return;
      
      const currData = data ? [...data, ...response.data.items] : response.data.items
      setData(currData);
    } catch (error) {
      console.error(error);
      setData(null);
      setError(error);
      setIsLoading(false);
      toast.dismiss();
      toast.error(error.message);
    }
  };

  useEffect(() => {
    // getData(); // Use this to get Data from Database and remove DUMMY Data
  }, [page]);

  const nextPage = async () => {
    if (!hasMore) return;

    setPage((page) => Number(page) + 1);
  };


  const sideScroll = (element,direction,speed,distance,step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
        if(direction === 'left'){
            element.current.scrollLeft -= step;
        } else {
            element.current.scrollLeft += step;
        }

        scrollAmount += step;

        if(scrollAmount >= distance){
            window.clearInterval(slideTimer);
        }
    }, speed);
}


  const onLeft = () => {
    if(divRef.current.scrollLeft === 0) {
      return
    }
    sideScroll(divRef, 'left', speed, cardWidth, 20);
  }

  const onRight =  () => {
    sideScroll(divRef, 'right', speed, cardWidth, 20);
  }

  const handleScroll = e => {
    const totalWidth = Number(divRef.current.scrollWidth) - Number(divRef.current.offsetWidth)

    if(divRef.current.scrollLeft > 0) {
      setHasLeftCards(true)
    }

    if(divRef.current.scrollLeft >= totalWidth) {
      setHasRightCards(false)
    }

    if(divRef.current.scrollLeft < totalWidth) {
      setHasRightCards(true)
    }

    if(divRef.current.scrollLeft === 0) {
      setHasLeftCards(false)
    }  
    
    if(divRef.current.scrollLeft >= (totalWidth - cardWidth)) {
      nextPage()
    }
  }

  if (isLoading) {
    return (
      <CenterComponent>
        <Loader />
      </CenterComponent>
    );
  }

  if (!data) {
    return (<CenterComponent>No Shop data available.</CenterComponent>)
  }

  if (error) {
    return (<CenterComponent>{error}</CenterComponent>)
  }

  
  return (
  <div className="flex flex-col gap-3 mt-10">
        <div className="flex items-center justify-between">
          <div className="font-semibold text-lg leading-6 text-[#110C22]">
            Similar Shops nearby
          </div>
          <div className="flex items-center gap-1">
            <button
              className="border rounded-lg px-3 py-2.5 disabled:opacity-25"
              disabled={!hasLeftCards}
              onClick={onLeft}
            >
              <img
                className="rotate-180"
                src={SmallArrowLeftIcon}
                alt="arrow-left"
              />
            </button>
            <button className="border rounded-lg px-3 py-2.5 disabled:opacity-25" onClick={onRight} disabled={!hasRightCards}>
              <img src={SmallArrowRightIcon} alt="arrow-right" />
            </button>
          </div>
        </div>


        <div ref={divRef} className="mt-1 flex items-center gap-6 overflow-y-scroll hide-scrollbar pointer-events-none" onScroll={handleScroll} onLoad={handleScroll}>
           {data.map(shop => {
            if(shop.id === shopId) return null;

            return (<SimilarShopsCard key={shop.id}
            width={cardWidth} 
            data={shop}
            currLatitude={currLatitude}
            currLongitude={currLongitude}
           />)
           })}
        </div>

</div>)
}

export default SimilarShops