import Button from "../button/button";
import ArrowRightIcon from "../../assets/arrow-right.svg";
import { updateSavedShopMultipleKeys } from "../../lib/redux/slices/saved-shop.slice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const Footer = ({
  onSave,
  onCancel,
  onNext,
  btnFirstText = "Save",
  btnSecondText = "Cancel",
  btnThirdText = "Next",
  showNextArrow = true,
}) => {
  const dispatch = useDispatch();
  const claimShopDetails = useSelector((state) => state.claimShop);

  const onSaveLocally = () => {
    dispatch(
      updateSavedShopMultipleKeys({
        savedShopDetails: claimShopDetails,
        isSaved: true,
      })
    );
    toast.success("Data saved locally.");
  };

  return (
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between border-t relative bottom-0 w-full md:pt-6 md:px-14 md:pb-8 gap-3 pt-5 px-2 pb-5">
      <div>
        <Button
          bgColor="transparent"
          textColor="black"
          onClick={onSave || onSaveLocally}
        >
          {btnFirstText}
        </Button>
      </div>
      <div className="flex items-center justify-between gap-3">
        <div className="w-1/2">
          <Button bgColor="transparent" textColor="black" onClick={onCancel}>
            {btnSecondText}
          </Button>
        </div>
        <div className="w-1/2">
          <Button onClick={onNext}>
            <div className="flex items-center justify-center">
              <span>{btnThirdText}</span>
              {showNextArrow && (
                <div className="ml-1">
                  <img src={ArrowRightIcon} alt="arrow-right-icon" />
                </div>
              )}
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
