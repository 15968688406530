import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Root from "./routes/root";
import "./App.scss";
import { appStore, persistor } from "./lib/redux/store/app.store";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <Provider store={appStore}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App relative">
          <Root />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
