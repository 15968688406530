import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button/button";
import { Heading, SubHeading } from "../../components/headings/headings";
import ImgIcon from "../../assets/img-icon.svg";
import CloseDarkIcon from "../../assets/close-dark.svg";
import ShopImg from "../../assets/shop-img.jpg";
import Body, { CustomHeightContainer } from "../../components/body/body";
import ImageSection from "../../components/image-section/image-section";
import Footer from "../../components/footer/footer";
import { useLocation } from "../../hooks/use-location";
import AxiosInstance from "../../lib/axios/axios-instance";
import { updateLocation } from "../../lib/redux/slices/location.slice";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCliamShopDetailsMultipleKeys,
  updateCliamShopDetails,
  clearCliamShopDetails,
} from "../../lib/redux/slices/claim-shop.slice";
import { clearSavedShop } from "../../lib/redux/slices/saved-shop.slice";
import { blobToBase64 } from "../../utils/convert-to-base64";
import "./store-front-picture.scss";
import { toast } from "react-toastify";
import { getErrorAddDetailsPage } from "../../utils/validation";
import { Loader } from "../../components/center-component/center-component";
import { clearValidMobileNumber } from "../../lib/redux/slices/valid-mobile-number.slice";

const CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: "environment" },
};

const axiosInstance = AxiosInstance.getInstance();

const LocationDetail = ({ capturedAddress, locationError, isLocating }) => {
  if (capturedAddress) {
    return (
      <div>
        <SubHeading>Your captured shop location: {capturedAddress}</SubHeading>
      </div>
    );
  }

  if (locationError) {
    return (
      <div>
        <SubHeading>Location Error: {locationError?.message}</SubHeading>
      </div>
    );
  }

  if (isLocating) {
    <div>
      <SubHeading>Getting you Location Details...</SubHeading>
    </div>;
  }

  return null;
};

const AddPictureLayout = ({ isLocating, locationError }) => {
  const videoRef = useRef();
  const canvasRef = useRef();
  const dispatch = useDispatch();
  const claimShopDetails = useSelector((state) => state.claimShop);

  const [showInitialImgIcon, setShowInitialImgIcon] = useState(true);
  const [displayCamera, setDisplayCamera] = useState(false);
  const [mediaStreamError, setMediaStreamError] = useState(null);
  const [isLoadingCamera, setIsLoadingCamera] = useState(false);

  const openCamera = async () => {
    setShowInitialImgIcon(false);
    setIsLoadingCamera(true);
    setMediaStreamError(null);
    handleClear();
    try {
      if (!("mediaDevices" in navigator)) {
        navigator.mediaDevices = {};
      }

      if (!("getUserMedia" in navigator.mediaDevices)) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          var getUserMedia =
            navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

          if (!getUserMedia) {
            return Promise.reject(
              new Error("Camera API is not available in this device.")
            );
          }

          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }

      const stream = await navigator.mediaDevices.getUserMedia(CAPTURE_OPTIONS);
      canvasRef.current.style.display = "none";
      videoRef.current.style.display = "block";
      videoRef.current.srcObject = stream;
      setDisplayCamera(true);
      setIsLoadingCamera(false);
    } catch (err) {
      setIsLoadingCamera(false);
      setMediaStreamError(err);
    }
  };

  const onCapture = (blob) => storeImage(blob);

  const handleCapture = () => {
    setDisplayCamera(false);
    videoRef.current.style.display = "none";
    const context = canvasRef.current.getContext("2d");

    canvasRef.current.height = videoRef.current.videoHeight;
    canvasRef.current.width = videoRef.current.videoWidth;

    context.drawImage(
      videoRef.current,
      0,
      0,
      videoRef.current.videoWidth,
      videoRef.current.videoHeight,
      0,
      0,
      videoRef.current.videoWidth,
      videoRef.current.videoHeight
    );

    canvasRef.current.toBlob((blob) => onCapture(blob), "image/jpeg", 1);

    videoRef.current.srcObject.getVideoTracks().forEach(function (track) {
      track.stop();
    });
  };

  const onClear = () => updateKeyValue("storeFrontPicture", null);

  const handleClear = () => {
    const context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    onClear();
  };

  const updateKeyValue = (key, value) => {
    dispatch(
      updateCliamShopDetails({
        key,
        value,
      })
    );
  };

  const storeImage = async (blob) => {
    if (!blob) return;
    const base64Url = await blobToBase64(blob);
    updateKeyValue("storeFrontPicture", base64Url);
  };

  return (
    <>
      {claimShopDetails?.storeFrontPicture && (
        <div className="border-b p-6">
          <p className="font-normal text-sm">
            You can add posts, discoveries, collections to profile for your
            viewers to connect with you.
          </p>
        </div>
      )}
      <Body>
        <Heading>Claim Shop - Add Store Front Picture</Heading>
        <div className="my-5">
          <SubHeading>
            Dodong needs to verify that you own this shop. Kindly take a photo
            that includes your present location and the front of your store to
            ensure accurate picture.
          </SubHeading>
        </div>
        <div className="font-light text-sm underline">
          Learn more about verification
        </div>

        <div className="flex flex-col items-center justify-center mt-3 border rounded-2xl border-dashed gap-5 pt-12 pl-0 pb-24 mb-5">
          {showInitialImgIcon && !claimShopDetails?.storeFrontPicture && (
            <div className="relative">
              <div>
                <img className="custom-shadow" src={ImgIcon} alt="img-icon" />
              </div>
              <div>
                <Button onClick={openCamera}>
                  <span className="font-normal text-xs">
                    Take Store Front Picture
                  </span>
                </Button>
              </div>
            </div>
          )}

          <div className="flex flex-col items-center text-center">
            <>
              {mediaStreamError && (
                <>Camera Error: {mediaStreamError?.message}</>
              )}
              <div className="relative">
                {isLoadingCamera && <Loader />}
                <div className="block">
                  <canvas ref={canvasRef} style={{ display: "none" }} />
                  <video autoPlay ref={videoRef} style={{ display: "none" }} />
                </div>
              </div>
              {displayCamera && (
                <div className="mt-3">
                  <div>
                    <Button onClick={handleCapture}>Take a picture</Button>
                  </div>
                </div>
              )}
            </>
          </div>

          <div className="mt-3 flex flex-col items-center justify-center m-h-96 gap-6">
            {claimShopDetails?.storeFrontPicture && (
              <>
                <div className="rounded-lg overflow-hidden md:w-[640px] md:h-[480px]">
                  <ImageSection
                    onBtnClick={openCamera}
                    imgUrl={claimShopDetails.storeFrontPicture}
                  />
                </div>
                <div>
                  <Button onClick={openCamera}>
                    <span className="font-normal text-xs">Re-caputre</span>
                  </Button>
                </div>
              </>
            )}

            {(displayCamera || claimShopDetails?.storeFrontPicture) && (
              <div className="px-2">
                <LocationDetail
                  capturedAddress={claimShopDetails?.capturedAddress}
                  locationError={locationError}
                  isLocating={isLocating}
                />
              </div>
            )}
          </div>
        </div>
      </Body>
    </>
  );
};

const Modal = ({ onSave, onClose, onCancel, isSaving }) => {
  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex mt-36 md:mt-0 md:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="flex justify-between items-center mt-3 sm:ml-4 sm:mt-0 sm:text-left">
                <div>
                  <h3
                    className="text-sm font-semibold leading-6"
                    id="modal-title"
                  >
                    Are you sure
                  </h3>
                </div>
                <div>
                  <button className="p-1 text-black" onClick={onClose}>
                    <img src={CloseDarkIcon} alt="close-icon" />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end">
              <div className="p-6 flex gap-2 items-center justify-end w-fit">
                <Button
                  bgColor="transparent"
                  textColor="#110C22"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button disabled={isSaving} onClick={onSave}>
                  {isSaving ? "Saving..." : "Save"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SubmittedModal = ({ shopId }) => {
  const navigate = useNavigate();

  const onSubscribe = () => {
    if (!shopId) return;
    navigate(`/${shopId}/subscribe`);
  };

  const onCancel = () => {
    navigate(`/`);
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-neutral-500 transition-opacity"></div>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex mt-36 md:mt-0 md:min-h-full items-end justify-center text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:max-w-lg px-3">
            <div className="bg-white mt-3 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="flex flex-col justify-between items-center mt-3 sm:mt-0">
                <Heading>Claim Submitted</Heading>
                <SubHeading>
                  Your shop will be listed post successful verification.
                </SubHeading>
                <div className="p-6 flex gap-4 items-center justify-center">
                  <Button
                    onClick={onCancel}
                    bgColor="transparent"
                    textColor="#110C22"
                  >
                    Cancel
                  </Button>
                  <Button onClick={onSubscribe}>Subscribe</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CapturedLocationWithPhoto = () => {
  return (
    <>
      <div className="border-b p-6">
        <p className="font-normal text-sm">
          You can add posts, discoveries, collections to profile for your
          viewers to connect with you.
        </p>
      </div>
      <Body>
        <div className="mb-3">
          {/* <SubmittedModal /> */}
          <div>
            <div className="mt-3 flex flex-col items-center justify-center m-h-96 gap-6">
              <div className="rounded-lg overflow-hidden w-[500px] h-[500px]">
                <ImageSection imgUrl={ShopImg} />
              </div>
              <div>
                <Button>
                  <span className="font-normal text-xs">Re-caputre</span>
                </Button>
              </div>
              <div>
                <SubHeading>
                  Your captured shop location: Kalkaji, New Delhi
                </SubHeading>
              </div>
            </div>
          </div>
        </div>
      </Body>
    </>
  );
};

const StoreFrontPicture = () => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [shopId, setShopId] = useState(null);

  const [capturedAddress, setCapturedAddress] = useState("");
  const { isLocating, location, error } = useLocation();

  const dispatch = useDispatch();
  const claimShopDetails = useSelector((state) => state.claimShop);

  useEffect(() => {
    getLocationDetal();
  }, [location]);

  const navigate = useNavigate();

  const onCancel = () => {
    navigate("/add-shop");
  };

  const getError = (claimShopDetails) => {
    const { storeFrontPicture, lat, long, capturedAddress } = claimShopDetails;

    if (!storeFrontPicture) {
      return "Please provide the store front picture.";
    } else if (!lat || !long) {
      return "Location access is mandatory for the verification process.";
    } else if (!capturedAddress) {
      return "Please provide the location access to continue";
    }

    if (getErrorAddDetailsPage(claimShopDetails)) {
      return "Please fill the details in the previous page.";
    }

    return "";
  };

  const onNext = () => {
    const error = getError(claimShopDetails);

    if (error) {
      return toast.error(error);
    }
    setConfirmModal(true);
  };

  const onSave = async () => {
    setIsSaving(true);
    try {
      const { data } = await axiosInstance.post(
        "/claim-shops",
        claimShopDetails
      );
      setShopId(data.id);
      dispatch(clearSavedShop());
      dispatch(clearCliamShopDetails());
      dispatch(clearValidMobileNumber());
      setIsSaving(false);
      setSaveModal(true);
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.message?.toString());
      setIsSaving(false);
    }
  };

  const onCancelConfirmModal = () => {
    setConfirmModal(false);
  };

  const onCancelSubmittedModal = () => {
    setSaveModal(false);
  };

  const getLocationDetal = async () => {
    if (!location) {
      return;
    }

    try {
      const response = await axiosInstance.get("/google-apis/address-lookup", {
        params: {
          lat: location.latitude,
          long: location.longitude,
        },
      });

      dispatch(
        updateLocation({
          latitude: location.latitude,
          longitude: location.longitude,
        })
      );

      dispatch(
        updateCliamShopDetailsMultipleKeys({
          lat: location.latitude,
          long: location.longitude,
          capturedAddress: response.data.plus_code.compound_code,
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  if (saveModal) {
    return <SubmittedModal shopId={shopId} onCancel={onCancelSubmittedModal} />;
  }

  return (
    <div>
      <CustomHeightContainer>
        <AddPictureLayout
          location={location}
          capturedAddress={capturedAddress}
          isLocating={isLocating}
          locationError={error}
        />
      </CustomHeightContainer>
      <Footer
        btnThirdText="Finish"
        onNext={onNext}
        onCancel={onCancel}
        showNextArrow={false}
      />
      {confirmModal && (
        <Modal
          onClose={onCancelConfirmModal}
          onSave={onSave}
          onCancel={onCancelConfirmModal}
          isSaving={isSaving}
        />
      )}
    </div>
  );
};

export default StoreFrontPicture;
