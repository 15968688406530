import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  latitude: undefined,
  longitude: undefined,
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    updateLocation: (state, action) => {
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
    },
    clearLocation: () => initialState,
  },
});

export const { updateLocation, clearLocation } = locationSlice.actions;

export default locationSlice.reducer;
