import { Heading } from "../../components/headings/headings";
import Body from "../../components/body/body";

const ShippingPolicy = () => {
  return (
    <Body>
      <div>
        <Heading>Shipping Policy</Heading>
        <div className="flex flex-col gap-5 mt-5">
          <div className="font-semibold text-lg">Dodong Pvt Ltd.</div>
          <div className="font-semibold text-lg">
            Effective Date: 5 May 2024
          </div>
          <p>
            Welcome to Dodong! As an e-commerce platform specializing in
            subscription-based digital content, we understand that clear
            communication regarding our delivery methods is essential. This
            Shipping Policy is designed to explain how we deliver our services
            to you and ensure you understand the process of accessing the
            digital content you subscribe to.
          </p>

          <div>
            <ul className="list-none pl-3">
              <li>
                <p className="font-semibold text-sm">1. Digital Delivery</p>
                <p>
                  Since Dodong offers digital content through a subscription
                  model, there are no physical goods shipped to your address.
                  All subscribed content is delivered electronically through our
                  website or mobile application.
                </p>
              </li>
              <li>
                <p className="font-semibold text-sm">a. Accessing Content</p>
                <p>
                  Upon purchasing a subscription, you will gain immediate access
                  to the content available under your chosen subscription plan.
                  Access to content is contingent upon successful payment
                  confirmation. Once subscribed, you can view, stream, or
                  interact with your content at any time during your
                  subscription period through your Dodong account.
                </p>
              </li>
              <li>
                <p className="font-semibold text-sm">b. Availability</p>
                <p>
                  Our services are accessible 24/7, barring any maintenance or
                  updates to our platform, which we will communicate to you in
                  advance whenever possible. In cases of unexpected downtime, we
                  strive to restore access promptly and ensure that you are
                  informed of the status.
                </p>
              </li>
              <li>
                <p className="font-semibold text-sm">2. Account Activation</p>
                <p>
                  Your subscription begins as soon as your initial payment is
                  processed. You will receive an email confirming your
                  subscription details along with instructions on how to access
                  your account and start using the service immediately.
                </p>
              </li>
              <li>
                <p className="font-semibold text-sm">
                  3. Subscription Management
                </p>
                <p>
                  You can manage your subscription settings, including renewal
                  preferences and account details, through your Dodong account
                  dashboard. This self-service portal allows you to control your
                  subscription and ensures continuous access to our services.
                </p>
              </li>
              <li>
                <p className="font-semibold text-sm">
                  4. Updates and Notifications
                </p>
                <p>
                  We will send you notifications about any significant updates
                  to our content library or any changes to your subscription
                  plan. Notifications may include information on new features,
                  adjustments in subscription fees (if any), and other relevant
                  updates that could affect your access or subscription status.
                </p>
              </li>

              <li>
                <p className="font-semibold text-sm">5. Customer Support</p>
                <p>
                  Should you encounter any issues with accessing your
                  subscription or require assistance with account settings, our
                  customer support team is available to help you. Please contact
                  us at{" "}
                  <a href="mailto:dodong.business@gmail.com">
                    dodong.business@gmail.com
                  </a>{" "}
                  for prompt assistance.
                </p>
              </li>
              <li>
                <p className="font-semibold text-sm">
                  6. Modifications to Delivery Policy
                </p>
                <p>
                  Dodong reserves the right to modify this Shipping Policy at
                  any time to reflect changes in our operational practices or
                  the needs of our company and customers. We will notify you of
                  any significant changes by updating the date at the top of
                  this policy and, if necessary, through other communication
                  channels such as email.
                </p>
              </li>

              <li>
                <p className="font-semibold text-sm">7. Contact Us</p>
                <p>
                  If you have any questions or concerns regarding this Shipping
                  Policy or the delivery of our services, please feel free to
                  contact our customer service team at{" "}
                  <a href="mailto:dodong.business@gmail.com">
                    dodong.business@gmail.com
                  </a>
                  . By subscribing to our services, you agree to the terms laid
                  out in this Shipping Policy.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Body>
  );
};

export default ShippingPolicy;
