import { Heading } from "../../components/headings/headings";
import Body from "../../components/body/body";

const ContactUs = () => {
  return (
    <Body>
      <div>
        <Heading>Contact us</Heading>
        <div className="flex flex-col mt-5">
          <p className="font-semibold text-lg">DODONG PRIVATE LIMITED</p>
          <p className="font-light"><b>Address:</b> Plot No A/10, Ashok Nagar,
          Near Arya Palace, Bhubaneswar, Khorda, Orissa, India 751009</p>
          <div className="mt-4">
            If you have any questions or queries, please contact us at{" "}
            <a href="mailto:dodong.business@gmail.com">
              dodong.business@gmail.com
            </a>
            .
          </div>
        </div>
      </div>
    </Body>
  );
};

export default ContactUs;
