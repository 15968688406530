export const SHOP_TYPES = new Array(
  "Retailers",
  "Wholesalers",
  "Distributors",
  "Manufacturers",
  "Suppliers",
  "Service Providers",
  "Franchisees",
  "Online Retailers",
  "Specialty Stores",
  "Pop-up Shops"
);

