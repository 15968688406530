import { useEffect, useState, useCallback, useRef } from "react";
import AxiosInstance from "../../lib/axios/axios-instance";
import {
  BackgroundImageSection,
} from "../../components/image-section/image-section";
import SmallArrowRightIcon from "../../assets/small-arrow-right.svg";
import SmallArrowLeftIcon from "../../assets/small-arrow-left.svg";
import CenterComponent, {
  Loader,
} from "../../components/center-component/center-component";
import { toast } from "react-toastify";
import FilterIcon from "../../assets/Filter.svg";

export const DUMMY_DATA = [
        {
            "id": 61,
            "name": "Wooden Street - Furniture 2",
            "address": "No.163/1, 2nd Floor, BLOCK-Z1, No.201, BRT Road, above Sakti Sports Office, Roseland",
            "lat": 31.6861745,
            "long": 76.5213092
        },
        {
            "id": 73,
            "name": "Wooden Street - Furniture 3",
            "address": "No.163/1, 2nd Floor, BLOCK-Z1, No.201, BRT Road, above Sakti Sports Office, Roseland",
            "lat": 31.6861745,
            "long": 76.5213092
        },
        {
            "id": 74,
            "name": "Wooden Street - Furniture 4",
            "address": "No.163/1, 2nd Floor, BLOCK-Z1, No.201, BRT Road, above Sakti Sports Office, Roseland",
            "lat": 31.6861745,
            "long": 76.5213092
        },
        {
            "id": 76,
            "name": "Wooden Street - Furniture 6",
            "address": "No.163/1, 2nd Floor, BLOCK-Z1, No.201, BRT Road, above Sakti Sports Office, Roseland",
            "lat": 31.6861745,
            "long": 76.5213092
        },
        {
            "id": 77,
            "name": "Wooden Street - Furniture 7",
            "address": "No.163/1, 2nd Floor, BLOCK-Z1, No.201, BRT Road, above Sakti Sports Office, Roseland",
            "lat": 31.6861745,
            "long": 76.5213092
        },
        {
            "id": 78,
            "name": "Wooden Street - Furniture 8",
            "address": "No.163/1, 2nd Floor, BLOCK-Z1, No.201, BRT Road, above Sakti Sports Office, Roseland",
            "lat": 31.6861745,
            "long": 76.5213092
        },
        {
            "id": 79,
            "name": "Wooden Street - Furniture 9",
            "address": "No.163/1, 2nd Floor, BLOCK-Z1, No.201, BRT Road, above Sakti Sports Office, Roseland",
            "lat": 31.6861745,
            "long": 76.5213092
        },
        {
            "id": 80,
            "name": "Wooden Street - Furniture 10",
            "address": "No.163/1, 2nd Floor, BLOCK-Z1, No.201, BRT Road, above Sakti Sports Office, Roseland",
            "lat": 31.6861745,
            "long": 76.5213092
        },
        {
            "id": 81,
            "name": "Wooden Street - Furniture 11",
            "address": "No.163/1, 2nd Floor, BLOCK-Z1, No.201, BRT Road, above Sakti Sports Office, Roseland",
            "lat": 31.6861745,
            "long": 76.5213092
        },
        {
            "id": 82,
            "name": "Wooden Street - Furniture 12",
            "address": "No.163/1, 2nd Floor, BLOCK-Z1, No.201, BRT Road, above Sakti Sports Office, Roseland",
            "lat": 31.6861745,
            "long": 76.5213092
        }
    ]

const axiosInstance = AxiosInstance.getInstance();

const SimilarProductsCard = ({width, data, currLatitude, currLongitude }) => {

    const { name, address, lat, long } = data;

  return (
<div style={{width: `${width}px`}}>
  <div style={{width: `${width}px`}} className={`h-44 rounded-tl-2xl rounded-tr-2xl overflow-hidden`}>
    <div className="h-[inherit] w-[inherit] overflow-hidden relative">
      <BackgroundImageSection imgUrl={"https://premium-section-uploads.s3.ap-south-1.amazonaws.com/1-store-picture"} />
    </div>
  </div>
          <div className="h-auto flex flex-col justify-center gap-2 border border-t-0 rounded-bl-2xl rounded-br-2xl py-3 px-4">
            
              <div>
                <div>
                    <p className="font-normal text-xs text-[#9c98a2]">Ikea</p>
                 </div>
                <h3 className="font-semibold text-sm leading-6 text-[#110C22]">
                    {name}
                </h3>
              </div>

          <div className="flex items-center justify-between">
              <div className="flex flex-col gap-1">
                <p className="font-medium text-lg leading-6 text-[#110C22]">
                  $59.99
                </p>
              </div>
              <button>
                <img src={FilterIcon} alt="Filter-icon" />
              </button>
          </div>

      </div>
    </div>)}

const SimilarProducts = ({ 
    cardWidth = 330, 
    currLatitude, 
    currLongitude, 
    categories, 
    subCategories, 
    shopId }) => {
  const divRef = useRef(null)
  const speed = 30

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [data, setData] = useState(DUMMY_DATA); // Should be null instead of DUMMY_DATA while getting data from Db
  const [isLoading, setIsLoading] = useState(false); // set true
  const [error, setError] = useState(null);
  const [hasLeftCards, setHasLeftCards] = useState(false);
  const [hasRightCards, setHasRightCards] = useState(true);
  

  const getData = async () => {
    try {
      const response = await axiosInstance.get("/claim-shops/similar-shops", {
        params: {
          categoryIds: categories.map(category => category.id),
          subCategoryIds: subCategories.map(subCategory => subCategory.id),
          page: page,
          currentShopId: shopId
        },
      });


      setIsLoading(false);
      setHasMore(response.data?.links?.next !== "");

      if (!response.data.items.length) return;

      const currData = data ? [...data, ...response.data.items] : response.data.items
      setData(currData);
    } catch (error) {
      console.error(error);
      setData(null);
      setError(error);
      setIsLoading(false);
      toast.dismiss();
      toast.error(error.message);
    }
  };

  useEffect(() => {
    // getData(); // Use this to get Data from Database and remove DUMMY Data
  }, [page]);

  const nextPage = async () => {
    if (!hasMore) return;

    setPage((page) => Number(page) + 1);
  };


  const sideScroll = (element,direction,speed,distance,step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
        if(direction === 'left'){
            element.current.scrollLeft -= step;
        } else {
            element.current.scrollLeft += step;
        }

        scrollAmount += step;

        if(scrollAmount >= distance){
            window.clearInterval(slideTimer);
        }
    }, speed);
}


  const onLeft = () => {
    if(divRef.current.scrollLeft === 0) {
      return
    }
    sideScroll(divRef, 'left', speed, cardWidth, 20);
  }

  const onRight = () => {
    sideScroll(divRef, 'right', speed, cardWidth, 20);
  }

  const handleScroll = e => {
    const totalWidth = Number(divRef.current.scrollWidth) - Number(divRef.current.offsetWidth)

    if(divRef.current.scrollLeft > 0) {
      setHasLeftCards(true)
    }

    if(divRef.current.scrollLeft >= totalWidth) {
      setHasRightCards(false)
    }

    if(divRef.current.scrollLeft < totalWidth) {
      setHasRightCards(true)
    }

    if(divRef.current.scrollLeft === 0) {
      setHasLeftCards(false)
    }

    if(divRef.current.scrollLeft >= (totalWidth - cardWidth)) {
      nextPage()
    }    
  }

  if (isLoading) {
    return (
      <CenterComponent>
        <Loader />
      </CenterComponent>
    );
  }

  if (!data) {
    return (<CenterComponent>No data available.</CenterComponent>)
  }

  if (error) {
    return (<CenterComponent>{error}</CenterComponent>)
  }

  return (
  <div className="flex flex-col gap-3 mt-10">
        <div className="flex items-center justify-between">
          <div className="font-semibold text-lg leading-6 text-[#110C22]">
            Price comparison from near by shops
          </div>
          <div className="flex items-center gap-1">
            <button
              className="border rounded-lg px-3 py-2.5 disabled:opacity-25"
              disabled={!hasLeftCards}
              onClick={onLeft}
            >
              <img
                className="rotate-180"
                src={SmallArrowLeftIcon}
                alt="arrow-left"
              />
            </button>
            <button className="border rounded-lg px-3 py-2.5 disabled:opacity-25" onClick={onRight} disabled={!hasRightCards}>
              <img src={SmallArrowRightIcon} alt="arrow-right" />
            </button>
          </div>
        </div>


        <div ref={divRef} className="mt-1 flex items-center gap-6 overflow-y-scroll hide-scrollbar" onScroll={handleScroll} onLoad={handleScroll}>
           {data.map(shop => {
            if(shop.id === shopId) return null;

            return (<SimilarProductsCard key={shop.id}
            width={cardWidth} 
            data={shop}
            currLatitude={currLatitude}
            currLongitude={currLongitude}
           />)
           })}
        </div>

</div>)
}

export default SimilarProducts