import { Heading } from "../../components/headings/headings";
import Body from "../../components/body/body";

const AboutUs = () => {
  return (
    <Body>
      <div>
        <Heading>About Dodong Premium</Heading>
        <div className="flex flex-col gap-2 mt-5">
          <div className="text-lg">At Dodong, we believe in empowering local businesses to thrive in the digital age. Dodong Premium is our commitment to providing exceptional value to shop owners like you. Here’s what sets us apart:</div>
          <div className="font-semibold text-lg">
            Our Story
          </div>
          <div className="text-lg">Dodong was born out of a simple idea: to bridge the gap between local businesses and their potential customers. We envisioned a platform where shop owners could showcase their unique offerings, connect with shoppers, and grow their businesses effortlessly.</div>


          <div className="font-semibold text-lg">
            Our Mission
          </div>
          <div className="text-lg">
            Our mission is clear: to empower local businesses by providing them with the tools, visibility, and support they need to succeed. Dodong Premium is an extension of this mission—a premium experience designed exclusively for you.
            </div>



          <div>
              <div className="font-semibold text-lg">
                Why Choose Dodong Premium?
              </div>

            <div className="font-semibold">
                1. Visibility and Priority:
            </div>  
            <ul className="pl-10 list-disc">
              <li>Your shop receives top placement in search results, ensuring maximum visibility.</li>
              <li>Be the first choice for customers seeking local products and services.</li>
            </ul>


            <div className="font-semibold">
                2. Advanced Features:
            </div> 
            <ul className="list-disc pl-10">
              <li>Unlock exclusive tools for promotions, analytics, and inventory management.</li>
              <li>Tailor your strategies to drive sales and enhance customer experiences.</li>
            </ul>


            <div className="font-semibold">
                3. Cash Discounts:
            </div> 
            <ul className="list-disc pl-10">
              <li>Incentivize shoppers with special discounts.</li>
              <li>Boost sales and create loyal customers who keep coming back.</li>
            </ul>


             <div className="font-semibold">
                4. Verified Trust:
            </div> 
            <ul className="list-disc pl-10">
              <li>Display the verified badge and earn credibility.</li>
              <li>Stand out as a reliable choice in a competitive market.</li>
            </ul>


            <div className="font-semibold">
                5. Dedicated Support:
            </div> 
            <ul className="list-disc pl-10">
              <li>Our team is here for you.</li>
              <li>Whether you have questions or need assistance, we’re committed to your success.</li>
            </ul>
          </div>

          <div>
              <div className="font-semibold text-lg">
                Transparent Pricing and Guarantee
              </div>

             <div className="font-semibold">
                1. Annual Subscription Fee: ₹ 1999/year
            </div> 
            <ul className="list-disc pl-10">
              <li>Renew annually at the same rate—no surprises.</li>
              <li>We believe in transparent pricing and fair business practices.</li>
            </ul>


            <div className="font-semibold">
                2. 30-Day Money-Back Guarantee:
            </div> 
            <ul className="list-disc pl-10">
              <li>If you’re not satisfied within the first 30 days, we’ll refund your subscription.</li>
              <li>No questions asked.</li>
            </ul>
          </div>



          <div className="font-semibold text-lg">
            Join Dodong Premium Today
          </div>
          <div className="text-lg">
              Elevate your business with Dodong Premium. We’re more than a platform; we’re your partner in growth. Let’s make your shop a local success story!
          </div>
        </div>
      </div>
    </Body>
  );
};

export default AboutUs;
